<template>
  <v-banner
    width="100%"
    color="#0089B5"
    rounded
    dark>
    <h2 class="text-center">{{ title }}</h2>
  </v-banner>
</template>
<script>
export default {
  name:'Bloque',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  }
}
</script>