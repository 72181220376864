<template>
  <div>
    <div width="100%" style="background-color: #CCC; border-radius: 3px; padding-left: 10px; border: 1px solid;">
      <span class="font-weight-bold text-uppercase h-4">{{ aItems.Pregunta }}</span>
    </div>
    <div width="50%" style="border: 1px solid; border-radius:3px; padding-left: 10px;">
      <div style="display: flex;">
        <v-container>
          <v-row dense>
            <v-col v-for="(oFirma, index) in aFirmas" :key="index" sm="3" md="3" lg="3" xl="3">
              <div id="sImgReference" >
                <v-img class="aFotos" style="width: 200px; height: 200px;" :src="oFirma" contain>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        color="blue lighten-3">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <br>
                <!-- <span>Nombre: {{ oDataProcess[0].Asistente.trim() }}</span> -->
                <span>Nombre: {{ aItems.Respuesta[index].Asistente}}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  name:'Firma',
  props: ['aItems'],
  data () {
    return {
      aFirmas: [],
    }
  },
  mounted() {
    this.proccessData()
  },
  methods: {
    proccessData () {
      this.aItems.Respuesta.forEach(async oFirma => {
        await axiosServices.getConfiguration(`FormDinamicoReporte_Base64/${oFirma.Firma}`, false, '').then(getBase64 => {
          this.aFirmas.push(getBase64.aData)
        })
      })
    },
    resize () {
      if (window.addEventListener) {  // all browsers except IE before version 9
        window.addEventListener("resize", onResizeEvent, true);
      }
      function onResizeEvent() {
        let divImgElement = document.getElementById("sImgReference")
        let newWidth = divImgElement.clientWidth
        let aImgsRenders = document.getElementsByClassName("aFotos")
        aImgsRenders.forEach(oElementImg => {
          oElementImg.style.width = `${newWidth}px`
          oElementImg.style.height = `${newWidth}px`
        })
      }
    },
  }
}

</script>
