<template>
  <div width="100%" style="background-color: #0089B5; border-radius: 3px; padding-left: 10px; border: 1px solid;" class="text-center">
      <span class="font-weight-bold text-uppercase h-4 white--text">{{ aItems.Pregunta }}</span>
  </div>
</template>

<script>
  export default {
    name:'SubFormulario',
    props: ['aItems'],
  }
</script>

<style lang="scss" scoped>

</style>