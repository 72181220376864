<template>
  <div>
    <v-container v-if="!bCargando">
      <v-row dense>
        <v-col sm="5" md="5" lg="5" xl="5">
          <v-btn v-if="getIdPrevPage !== ''" color="btncolor" small right text @click="volver()" dark>
            <v-icon left small dark>fas fa-reply</v-icon>
            <span class="font-weight-bold title">Volver</span>
          </v-btn>
        </v-col>
        
        <v-col sm="2" md="2" lg="2" xl="2" offset-md="5" justify="end">
          <v-btn right text small color="btncolor" @click="printSection()" dark> 
            <span class="font-weight-bold title">Exportar</span> 
            <v-icon small right dark>fa-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row  justify="center" align="center" class="imprimir ma-10" dense>
        <v-col sm="12" md="12" lg="12" xl="12" justify="center" align="center">
          <v-progress-circular
            :size="100"
            indeterminate
            color="primary"
        ></v-progress-circular>
        </v-col>
        <v-col sm="12" md="12" lg="12" xl="12" justify="center" align="center" class="mt-10">
          <h2>Cargando información ...</h2>
        </v-col>  
      </v-row>  
    </v-container>
    <div id="printPage">
      <v-container v-if="!bCargando">
        <div class="imprimir">
          <v-row dense>
            <v-col sm="12" md="12" lg="12" xl="12">
              <h2 id="titleForm" class="text-center my-5">
                FORMULARIO No. {{ $route.params.id }} - {{ aHeader.Titulo }}
              </h2>
            </v-col>
          </v-row>
          <v-row justify="center" dense>
            <v-col sm="12" md="4" lg="4" xl="4">
              <div id="sImgReference" style="border: 1px; text-align: center;">
                <v-img id="Logo" style="width: 200px;" :src="LogoImageURL" contain>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        color="blue lighten-3">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col sm="12" md="4" lg="4" xl="4">
              <div>
                <span class="font-weight-bold h4 text-uppercase">Usuario Gestor</span>
                <p class="text-capitalize">{{ aHeader.Usuario }}</p>
              </div>
            </v-col>
            <v-col sm="12" md="4" lg="4" xl="4">
              <div>
                <span class="font-weight-bold h4 text-uppercase">Fecha Elaboración</span>
                <p class="text-capitalize">{{ aHeader.Fecha }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row justify="center" align="center" dense>
          <v-col sm="12" md="12" lg="12" xl="12">
            <div v-for="(oData, indice) in aData" :key="indice">
              <v-card class="imprimir"
                outlined>
                <component :is="oData.TipoCampo"
                  :aItems="oData">
                </component>
              </v-card>
              <div v-if="oData.TipoCampo == 'SubFormulario'">
                <div v-for="(oSubData, index) in oData.Respuesta" :key="index">
                  <v-card class="imprimir">
                    <component :is="oSubData.TipoCampo"
                      :aItems="oSubData">
                    </component>
                  </v-card>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row  v-if="!bCargando" justify="center" align="center" class="imprimir" dense>
            <Bloque
              title="UBICACIÓN">
            </Bloque>
            <MapaView v-if="!bCargando"
              :key="nkeyRefresMap"
              :lat="aHeader.Latitud"
              :lng="aHeader.Longitud"
              :isDraggable="false">
            </MapaView>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="bShowExport" persistent width="250">
      <v-card
        color="primary"
        dark>
        <v-card-title>
          Generando PDF...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular
            active
            indeterminate
            color="white"
            class="mb-0">
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Bloque from '@/components/FormDinamicos/Componentes/Bloque.vue'
import SubFormulario from '@/components/FormDinamicos/Componentes/SubFormulario.vue'
import RespuestaSencilla from '@/components/FormDinamicos/Componentes/RespuestaSencilla.vue'
import Grupo from '@/components/FormDinamicos/Componentes/Grupo.vue'
import Foto from '@/components/FormDinamicos/Componentes/Foto.vue'
import Firma from '@/components/FormDinamicos/Componentes/Firma.vue'
import MultiSelect from '@/components/FormDinamicos/Componentes/Multiselect.vue'
import Correo from '@/components/FormDinamicos/Componentes/Correo.vue'
import { mapGetters } from 'vuex'
import MapaView from '@/components/Mapas/mapLocalizar.vue'
export default {
  name: 'FormDinamicoReporte',
  components: {
    Bloque,
    SubFormulario,
    RespuestaSencilla,
    Grupo,
    Foto,
    Firma,
    MultiSelect,
    Correo,
    MapaView
  },
  data () {
    return {
      bCargando : true,
      LogoImageURL : '',
      aHeader : [],
      aData : [],
      bShowExport : false,
      nkeyRefresMap : 0
    }
  },
  async mounted () {
    this.bCargando = true
    await this.getHeader()   // siempre de primero
    await this.getLogo()
    await this.getOrderData()
    this.nkeyRefresMap++
    this.bCargando = false


  },
  methods: {
    async getLogo () {
      let sParams = 'sPath=LogoImagen'
      const getPath = axiosServices.getConfiguration('FormDinamicoReporte_Path', false, sParams)

      this.LogoImageURL = await getPath.aData
    },
    async getHeader () {
      const getHeader = await axiosServices.getConfiguration(`FormDinamicoReporte_Header/${this.$route.params.id}`, false, '')

      this.aHeader = await getHeader.aData

      this.nkeyRefresMap++
    },
    async getOrderData () {
      const getBody = await axiosServices.getConfiguration(`FormDinamicoReporte_Body/${this.$route.params.id}`, false, '')

      this.aData = await getBody.aData

    },
    async printSection() {
      // Se imprime todo lo que este detro del div id printPage y los componentes principales que tengan la class imprimir
      this.bShowExport = true
      let elements = document.querySelectorAll("#printPage .imprimir")
      let doc = new jsPDF('p', 'mm', 'a4', true)
      doc.setFillColor(245);
      doc.rect(0, 0, 210, 700, "F");
      const imgWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight() - 5
      let position = 5
      let pageHeightLeft = pageHeight
      for (let i = 0; i < elements.length; i++) {
        await html2canvas(elements[i], {
          scale: 2,
          useCORS: true,
          allowTaint: true
        }).then(function(canvas) {
          let imgData = canvas.toDataURL('image/png', 1.0)
          let imgHeight = canvas.height * imgWidth / canvas.width
          // Comprobamos salto
          if (pageHeightLeft - imgHeight <= 0) {
            doc.addPage()
            pageHeightLeft = pageHeight
            position = 5 // Pintaremos en el inicio de la nueva pagina
          }
          doc.addImage(imgData, 'PNG', 0, position, (imgWidth - 2), imgHeight, '', 'FAST')
          position += imgHeight // Marcamos el siguiente inicio
          pageHeightLeft -= imgHeight // Marcamos cuanto espacio nos queda
        })
      }
      doc.save(`${document.getElementById('titleForm').textContent}.pdf`)
      this.bShowExport = false
    },
    resize () {
      if (window.addEventListener) {  // all browsers except IE before version 9
        window.addEventListener("resize", onResizeEvent, true);
      }
      function onResizeEvent() {
        let divImgElement = document.getElementById("sImgReference")
        let newWidth = divImgElement.clientWidth
        let oImgRender = document.getElementById("Logo")
        oImgRender.style.width = `${newWidth}px`
      }
    },
    volver() {
      this.$router.replace({path : `/GridDinamicos/${this.getIdPrevPage}`})
    }
  },
  computed: {
    ...mapGetters(['getIdPrevPage'])
  },
}
</script>

<style lang="scss" scoped>

</style>