<template>
    <Bloque
      :title="aItems.Pregunta">
    </Bloque>
</template>

<script>
import Bloque from '@/components/FormDinamicos/Componentes/Bloque.vue'

  export default {
    name:'Grupo',
    props: ['aItems'],
    components: {
      Bloque,
    },
    
  }
</script>

<style lang="scss" scoped>

</style>