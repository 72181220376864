<template>
  <div>
    <div width="100%" style="background-color: #CCC; border-radius: 3px; padding-left: 10px; border: 1px solid;">
      <span class="font-weight-bold text-uppercase h-4">{{ aItems.Pregunta }}</span>
    </div>
    <div v-for="(DataProcess, index) in aDataProcess" :key="index" width="100%" style="border: 1px solid; border-radius:3px; padding-left: 10px;">
      <span v-html="DataProcess"></span>
    </div>
  </div>
</template>
<script>
export default {
  name:'Multiselect',
  props: ['aItems'],
  data () {
    return {
      aDataProcess: []
    }
  },
  mounted() {
    this.proccessData()
  },
  methods: {
    proccessData () {
      this.aDataProcess = this.aItems.Respuesta.split(',')
    }
  }
}
</script>
