<template>
  <div>
    <div width="100%" style="background-color: #CCC; border-radius: 3px; padding-left: 10px; border: 1px solid;">
      <span class="font-weight-bold text-uppercase h-4">{{ aItems.Pregunta }}</span>
    </div>
    <div width="100%" style="border: 1px solid; border-radius:3px; padding-left: 10px;">
      <span>{{ aItems.Respuesta }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name:'Correo',
  props: ['aItems'],
  data() {
    return {
      aDataProcess: [],
    }
  },
}
</script>
<style>
  .double{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
</style>